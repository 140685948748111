import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum PfpLiquidityStatus {
    VacancyDeprioritized = 'VacancyDeprioritized',
    ManagerDeprioritized = 'ManagerDeprioritized',
    ManagerVacanciesHidden = 'ManagerVacanciesHidden',
}

export const PFP_LIQUIDITY_BAD_STATUSES = [
    PfpLiquidityStatus.ManagerDeprioritized,
    PfpLiquidityStatus.ManagerVacanciesHidden,
    PfpLiquidityStatus.VacancyDeprioritized,
];

export interface PpfpLiquidityWarning {
    status: PfpLiquidityStatus;
    vacancies: Array<{ id: number; name: string; areaName: string }> | undefined;
    responsesToBuy: number;
}

export default autoGeneratedReducer<PpfpLiquidityWarning>(null);
